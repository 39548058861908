<template>
  <div
    class="wrapper-triangle"
    :style="{
      width: size + 'rem',
      height: size + 'rem',
    }"
  >
    <div class="badge-inner" :style="[borderStyle]"></div>
    <div
      class="badge-text"
      :style="[
        {
          width: size + 'rem',
          height: size + 'rem',
        },
        textStyle,
      ]"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    size: {
      type: Number,
      default: 0.8,
    },
    bgColor: {
      type: String,
      default: "red",
    },
    text: {
      type: String,
      default: "进行中",
    },
    textStyle: Object,
  },
  computed: {
    borderStyle() {
      return {
        borderLeft: `${this.size}rem solid transparent`,
        borderTop: `${this.size}rem solid ${this.bgColor}`,
        borderLeft: `${this.size}rem solid transparent`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-triangle {
  position: relative;
  overflow: hidden;
}
.badge-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
}
.badge-text {
  position: absolute;
  font-size: 0.18rem;
  color: #fff;
  transform: rotate(45deg);
  transform-origin: center;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.14rem;
  right: -0.14rem;
}
</style>
