<template>
  <div v-if="info" class="wrapper">
    <div class="info-wrapper">
      <div class="fair-bg">
        <div class="img">
          <img class="cover" :src="info.imgUrl || defaultImg" />
          <triangle-badge
            class="badge"
            :text="info.status"
            :bgColor="getBadgeColor(info.status)"
          ></triangle-badge>
        </div>
      </div>
      <div class="title">
        {{ info.jobfairName }}
      </div>
      <div class="info">
        <div class="text">
          <div class="p1">
            <van-icon name="clock-o" />
            <span>
              {{ formatTime(info.beginTime) }} ~ {{ formatTime(info.endTime) }}
            </span>
          </div>
          <div class="p1" v-if="info.address">
            <van-icon name="location-o" />
            <span> {{ info.address }} </span>
          </div>
        </div>
        <div class="apply-wrapper" v-if="!!info.formContent">
          <van-button
            type="primary"
            v-if="info.status == '进行中' || info.status == '未开始'"
            @click="goApply"
            >求职者报名</van-button
          >
          <van-button v-if="info.status == '已结束'" disabled type="primary"
            >招聘会已结束</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import triangleBadge from "./triangle-badge";
import defaultImgUrl from "@/assets/1.jpg";
import moment from "dayjs";
export default {
  data() {
    return {};
  },
  props: {
    info: Object,
  },
  computed: {
    defaultImg() {
      return defaultImgUrl;
    },
  },
  components: { triangleBadge },
  methods: {
    getBadgeColor(val) {
      let color;
      switch (val) {
        case "进行中":
          color = "red";
          break;
        case "已结束":
          color = "#999";
          break;
        case "未开始":
          color = "green";
          break;
        default:
          break;
      }
      return color;
    },
    goApply() {
      this.$emit("goApply");
    },
    handleResumeConfirm() {
      this.postApply();
    },
    formatTime(val) {
      const date = moment(val);
      return date.format("YYYY年MM月DD日");
    },
    postApply() {
      this.$store
        .dispatch("jobFair/postJobfairsJoin", {
          jobfairCode: this.info.jobfairCode,
        })
        .then((res) => {
          uni.showToast({
            title: "报名成功",
            icon: "none",
          });
          this.$emit("applySuccess");
        })
        .catch((err) => {
          uni.showToast({
            title: err && err.msg ? err.msg : "服务器开小差了",
            icon: "none",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #fff;
  padding: 0.2rem 0.3rem;
}

.fair-bg {
  .img {
    width: 100%;
    border-radius: 0.2rem;
    overflow: hidden;
    position: relative;
    background-size: 100% 100%;
    img {
      width: 100%;
      height: 2.9rem;
    }
    .badge {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .text {
    flex: 1;
    padding-top: 0.1rem;
    .p1 {
      color: #666;
      margin-bottom: 0.1rem;
      span {
        font-size: 0.26rem;
        word-break: break-all;
        margin-left: 0.1rem;
      }
    }
  }
}

.info-wrapper {
  margin-bottom: 0.2rem;
  .title {
    font-size: 0.32rem;
    color: #000;
    font-weight: bold;
    line-height: 0.5rem;
    margin-bottom: 0.1rem;
  }
  .info {
    display: flex;
    align-items: center;
  }
  .text {
    flex: 1;
    margin-right: 0.5rem;
  }
}
</style>
