var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-triangle",style:({
    width: _vm.size + 'rem',
    height: _vm.size + 'rem',
  })},[_c('div',{staticClass:"badge-inner",style:([_vm.borderStyle])}),_c('div',{staticClass:"badge-text",style:([
      {
        width: _vm.size + 'rem',
        height: _vm.size + 'rem',
      },
      _vm.textStyle,
    ])},[_vm._v(" "+_vm._s(_vm.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }