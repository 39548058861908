import request from "@/utils/request";

// 获取岗位详情
export function getJobfairsInfo(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "POST",
    data,
  });
}
