import request from "@/utils/request";

//招聘会职位列表

export function getJobfairsJobList(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data,
  });
}

//招聘会企业
export function getJobfairsCmpyList(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data,
  });
}
