<!--
 * @Date: 2021-04-26 11:13:09
 * @LastEditTime: 2022-11-05 18:03:30
 * @Description: 培训card  
-->

<template>
  <div class="wrapper">
    <div v-for="(item, index) in data" :key="index" class="item-wrapper">
      <div class="cmpy-name">
        {{ item.cmpyName || "" }}
      </div>
      <template v-for="(jobItem, jobIndex) in item.jobList">
        <div
          class="job-item"
          :key="jobIndex"
          v-if="showMoreArr.indexOf(index) == -1 ? jobIndex < 2 : true"
          @click="goJobDetail(jobItem)"
        >
          <div class="row1">
            <div class="jobName">{{ jobItem.jobName }}</div>
            <div class="money">{{ getSalaryText(jobItem) }}</div>
          </div>
          <div class="tags">
            <div
              class="tag"
              v-for="(tagItem, tagIndex) in jobItem.reducingConditions"
              :key="tagIndex"
            >
              {{ tagItem }}
            </div>
          </div>
        </div>
      </template>
      <div class="bottom" v-if="item.jobList.length > 2">
        <div
          v-if="showMoreArr.indexOf(index) == -1"
          class="showMore"
          @click="showMore(index)"
        >
          查看更多
        </div>
        <div v-else class="showMore" @click="showLess(index)">收起</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSalaryText } from "@/utils";

export default {
  data() {
    return {
      showMoreArr: [],
    };
  },
  props: {
    data: Array,
  },
  computed: {},
  mounted() {},
  methods: {

    showMore(index) {
      this.showMoreArr.push(index);
    },
    showLess(index) {
      this.showMoreArr = this.showMoreArr.filter((item) => {
        return item != index;
      });
    },
    getSalaryText(data) {
      return getSalaryText(data);
    },
    goJobDetail(jobItem) {
      this.$router.push({
        path: "/jobDetail",
        query: {
          jobCode: jobItem.jobCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 0 0.3rem;
}
.item-wrapper {
  width: 100%;
  padding: 0 0.3rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  border-radius: 0.18rem;
}

.cmpy-name {
  height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
  line-height: 1rem;
}

.job-item {
  padding: 0.3rem 0;
  border-top: 1px solid #dfdfdf;
  .row1 {
    margin-bottom: 0.2rem;
    display: flex;

    .jobName {
      height: 0.3rem;
      font-size: 0.28rem;
      color: #666666;
      line-height: 0.3rem;
      flex: 2;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .money {
      height: 0.34rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #ff0000;
      line-height: 0.34rem;
      flex: 1;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .tags {
    .tag {
      display: inline-flex;
      border-radius: 0.08rem;
      border: 1px solid #999999;
      padding: 0 8px;
      font-size: 0.24rem;
      color: #999999;
      line-height: 0.32rem;
      margin-right: 0.2rem;
    }
  }
}
.bottom {
  text-align: center;
  .showMore {
    display: inline-block;
    padding-bottom: 0.2rem;
  }
}
</style>
