<template>
  <div class="wrapper">
    <van-list
      :value="loading"
      :finished="!tabList[currentTab].hasNext"
      finished-text="没有更多了"
      @load="handleScrollBottom"
    >
      <JobfairInfo @goApply="goApply" :info="info" />
      <div class="sticky-tab">
        <van-tabs v-model="currentTab" @change="changeTab">
          <van-tab
            :title="item.name"
            :name="index"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <template #title> {{ item.name }} · {{ item.total }} </template>
          </van-tab>
        </van-tabs>
      </div>
      <div class="list-wrapper">
        <div v-if="currentTab == 1">
          <van-sticky :offset-top="34">
            <div class="search-div" v-if="info">
              <div
                class="input"
                hover-class="none"
                hover-stop-propagation="false"
              >
                <van-search
                  v-model="tabList[1].keyWord"
                  placeholder="搜索职位"
                  shape="square"
                  @search="searchJob"
                  @clear="searchJob"
                />
              </div>
            </div>
          </van-sticky>
          <div class="job-list-box">
            <job-list noMoreBg="#f0f0f0" :data="tabList[1].list" />
          </div>
        </div>
        <div v-if="currentTab == 0">
          <van-sticky :offset-top="34">
            <div class="search-div" v-if="info">
              <div
                class="input"
                hover-class="none"
                hover-stop-propagation="false"
              >
                <van-search
                  v-model="tabList[0].keyWord"
                  placeholder="搜索企业"
                  shape="square"
                  @search="searchCmpy"
                  @clear="searchCmpy"
                />
              </div>
            </div>
          </van-sticky>
          <CmpyList :data="tabList[0].list" />
        </div>
      </div>
    </van-list>
    <div class="bottom" v-if="bottomShow">
      <van-notice-bar
        mode="closeable"
        text="微信关注“河南郑州航空港人才”，了解人才认定与奖励申报。"
        @close="closeNotice"
        @click="goWechat"
        color="#1989fa"
        background="#ecf9ff"
      />
    </div>
  </div>
</template>

<script>
import CmpyList from "./components/cmpy-list.vue";
import JobfairInfo from "./components/jobfair-info.vue";

import { getJobfairsInfo } from "@/api/recruit/jobfairs.js";
import {
  getJobfairsJobList,
  getJobfairsCmpyList,
} from "@/api/recruit/jobfairsApply.js";
import JobList from "@/components/JobList";

const defaulTabList = [
  {
    name: "企业大厅",
    pageNum: 1,
    pageSize: 5,
    hasNext: true,
    list: [],
    y: 0,
    total: 0,
    keyWord: "",
  },
  {
    name: "招聘大厅",
    pageNum: 1,
    pageSize: 10,
    hasNext: true,
    list: [],
    y: 0,
    total: 0,
    keyWord: "",
  },
];
export default {
  data() {
    return {
      id: "",
      info: null,
      tabList: JSON.parse(JSON.stringify(defaulTabList)),
      currentTab: 0, //当前tab
      loading: false,
      bottomShow: true,
    };
  },

  components: { CmpyList, JobfairInfo, JobList },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.id = id;
      this.getInfo();
      this.getJobList();
      this.getCmpyList();
    }
  },
  methods: {
    searchJob() {
      this.tabList[1].pageNum = 1;
      this.tabList[1].hasNext = true;
      this.tabList[1].list = [];
      this.tabList[1].total = 0;
      this.getJobList();
    },
    searchCmpy() {
      this.tabList[0].pageNum = 1;
      this.tabList[0].hasNext = true;
      this.tabList[0].list = [];
      this.tabList[0].total = 0;
      this.getCmpyList();
    },
    //滚动到底
    handleScrollBottom() {
      if (this.loading) {
        return;
      }
      if (this.currentTab == 1) {
        this.getJobList();
      } else {
        this.getCmpyList();
      }
    },
    getInfo() {
      const p = {
        apiName: "/recruit/jobfairs/info",
        paramMap: { jobfairCode: this.id },
        requestType: 1,
      };
      getJobfairsInfo(p).then((res) => {
        this.info = res.data;
      });
    },
    getJobList() {
      this.loading = true;
      const tab = this.tabList[1];
      const query = {
        jobfairCode: this.id,
        pageNum: tab.pageNum,
        pageSize: 10,
        jobName: tab.keyWord,
      };
      const params = {
        apiName: "/recruit/jobfairs-apply/queryJobH5OrXcx",
        paramMap: query,
        requestType: 1,
      };
      getJobfairsJobList(params).then((res) => {
        this.loading = false;
        if (tab.pageNum === 1) {
          tab.list = res.data.list;
        } else {
          tab.list = [...tab.list, ...res.data.list];
        }
        tab.pageNum++;
        tab.hasNext = res.data.isHashNextPage == 1 ? true : false;
        tab.noData = res.data.total === 0;
        tab.total = res.data.total;
      });
    },
    getCmpyList() {
      this.loading = true;
      const tab = this.tabList[0];
      const query = {
        jobfairCode: this.id,
        pageNum: tab.pageNum,
        pageSize: tab.pageSize,
        cmpyName: tab.keyWord,
      };
      const params = {
        apiName: "/recruit/jobfairs-apply/queryCmpy",
        paramMap: query,
        requestType: 2,
      };
      getJobfairsCmpyList(params).then((res) => {
        this.loading = false;
        if (tab.pageNum === 1) {
          tab.list = res.data.records;
        } else {
          tab.list = [...tab.list, ...res.data.records];
        }
        tab.pageNum++;
        tab.hasNext = res.data.current >= res.data.pages ? false : true;
        tab.noData = res.data.total === 0;
        tab.total = res.data.total;
      });
    },
    changeTab(e) {
      this.currentTab = e;
    },

    goApply() {
      window.location.href = `https://xgp-6gf8qcf2022b53fd-1308484041.tcloudbaseapp.com/jobfair-xgg1.html?id=${this.id}`;
    },

    closeNotice() {
      this.bottomShow = false;
    },
    goWechat() {
      let ua = navigator.userAgent.toLowerCase();
      let isWXWork = ua.match(/wxwork/i) == "wxwork";
      let isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      if (isWeixin) {
        window.location.href =
          "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMjQxNTI4Mw==#wechat_redirect";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-tab {
  z-index: 9;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
}
.search-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.3rem;
  .num {
    color: #409eff;
  }
  .input {
    width: 100%;
  }
}
.job-list-box {
  .wrapper {
    padding: 0 0.3rem;
  }
  ::v-deep .list {
    padding: 0 0.3rem;
    background-color: #fff;
  }
}

.bottom {
  position: fixed;
  width: 7.5rem;
  bottom: 0;
  left: 0;
  overflow: show;
}
</style>
